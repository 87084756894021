<template>
  <div class="static--content__container">
    <div
      class="
        static--content
        md:my-28
        my-16
        2xl:w-2/5
        xl:w-3/5
        md:w-3/4
        w-11/12
        mr-auto
        ml-auto
      "
    >
      <div class="content" v-html="getContent"></div>
    </div>
  </div>
</template>

<script>
import snarkdown from "snarkdown";

export default {
  name: "StaticContent",
  props: {
    path: {
      type: String,
      require: true,
    },
  },
  data: () => {
    return {
      content: "",
    };
  },
  methods: {
    enhancedSnarkdown: function (markdown) {
      /**
       * snarkdown doesn't automatically convert newlines
       *  to paragraph. This is an issue since we cannot style
       * the paragraphs without that.
       *
       * Refer to this issue: https://github.com/developit/snarkdown/issues/11
       * and this: https://github.com/developit/snarkdown/issues/75
       */
      return markdown
        .split(/(?:\r?\n){2,}/)
        .map((l) =>
          [" ", "\t", "#", "-", "*", ">"].some((char) => l.startsWith(char))
            ? snarkdown(l)
            : `<p>${snarkdown(l)}</p>`
        )
        .join("\n");
    },
    readContent: function () {
      /**
       * Read the content of the passed file based on
       * the path and accordingly store it in a local
       * variable.
       */
      fetch(this.getFilePath)
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          this.content = this.enhancedSnarkdown(text);
        });
    },
  },
  computed: {
    getContent() {
      return this.content;
    },
    getFilePath() {
      return this.path;
    },
  },
  mounted() {
    this.readContent();
  },
};
</script>

<style lang="scss" scoped>
.static--content__container {
  .static--content {
    .content {
      ::v-deep {
        h1 {
          @apply md:text-4xl text-3xl mt-12 mb-8;

          font-weight: bold;
        }

        h2 {
          @apply md:text-2xl text-xl mt-10 mb-6;

          font-weight: 500;
        }

        h3 {
          @apply md:text-xl;
          font-weight: 500;
        }

        p,
        ul {
          @apply text-gray-800;
          @apply md:leading-relaxed leading-normal;
          @apply my-6;
        }

        a {
          color: $cyan;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
