<template>
  <div class="privacy__container">
    <static-content path="/static/privacy.md" />
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import StaticContent from "../components/StaticContent.vue";

export default {
  name: "Privacy",
  components: { StaticContent, Footer },
};
</script>
